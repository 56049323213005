import React, { useState } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Select,
    Card,
    CardBody,
    ModalFooter,
    ModalBody,
    ModalContent,
    ModalHeader,
    Modal,
    useDisclosure,
    ModalOverlay,
    ModalCloseButton,
    Image,
    Text,
    Link,
} from "@chakra-ui/react";
const SignupUser = () => {
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        userType: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await axios.post('/api/saveDetails', formData);
        if (response.data.success) {
            alert('Details saved successfully!');
        }
    };

    return (
        <Flex alignItems="center" justifyContent="center">
            <Card m={2} mt={5}>
                <CardBody h={{ base: "87vh", lg: "125vh" }}>
                    <Flex justifyContent={"center"}>
                        <form className="bulkupload-section form-data">
                            <Heading mb={4} textAlign={"center"}>
                                Sign up User
                            </Heading>
                            <Flex
                                flexDirection="column"
                                justifyContent={"center"}
                                minWidth={{ base: 300, md: 500, lg: 500 }}
                                maxWidth={300}
                            >
                                <FormControl>
                                    <FormLabel fontSize="sm">First Name:</FormLabel>
                                    <Input
                                        type="text"
                                        name="fname"
                                        value={formData.fname}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel fontSize="sm">Last Name:</FormLabel>
                                    <Input
                                        type="text"
                                        name="lname"
                                        value={formData.lname}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel fontSize="sm">Email:</FormLabel>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel fontSize="sm">Phone:</FormLabel>
                                    <Input
                                        type="text"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </FormControl>



                                <Button type="submit" mt={4}>Sign up</Button>
                            </Flex>
                        </form>
                    </Flex>
                </CardBody>
            </Card>
        </Flex>
    );
};

export default SignupUser;
