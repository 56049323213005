import React, { useEffect } from "react";
import { axiosSource, postCall } from "../../services/httpService";
import { USER_API } from "../../services/apiRoutes";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Card,
  CardBody,
  ModalFooter,
  ModalBody,
  ModalContent,
  ModalHeader,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalCloseButton,
  Image,
  Text,
  Link,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { CONSTANTS } from "../../services/constants";
import { initialValues, validate } from "../../services/registerService";
import {
  handleToast,
  toastByStatus,
  toastErrorOnly,
} from "../../services/utils";
import { Country, State, City } from "country-state-city";
// import { createRazorpayOrder } from "../../services/payment";
import { RazorPay, initializeRazorpay, initializeGpay } from "../../services/paymentv2";
import qr from "../../images/logo.png";
import axios from 'axios';
import a3mindsupi from '../../images/a3minds_qr2.jpg';

function RegisterUser({ screenName = "Register User", create = true }) {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [institutes, setInstitutes] = useState([]);
  const [grades, setGrades] = useState([]);
  const [gradeSections, setGradeSections] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [selectedInstype, setSelectedInstype] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedGradeSection, setSelectedGradeSection] = useState([]);

  const [userDetails, setUserDetails] = useState({
    isLoading: false,
    data: null,
  });

  const baseUrl = "/";


  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({});

  useEffect(() => {
    fetchPrograms();
    fetchInstitutes();
  }, []);

  useEffect(() => {
    //if (formValues.institute) {
    if (formValues.institute && selectedInstitute && selectedInstype) {
      fetchGrades(selectedInstitute, selectedInstype);
    }
  }, [formValues.institute]);

  useEffect(() => {
    //if (formValues.grade) {
    if (formValues.grade && selectedInstitute && selectedInstype) {
      fetchGradeSections(selectedInstitute, selectedInstype);
    }
  }, [formValues.grade]);

  function appLog(message) {
    //console.log(message);
  }
  const fetchPrograms = async () => {
    const apiUrl = baseUrl + "adm/index.php?q=p";
    const response = await axios.get(apiUrl);
    appLog("prog");
    appLog(response);
    setPrograms(response.data);
  };

  const fetchInstitutes = async () => {
    const apiUrl = baseUrl + "adm/index.php?q=i";
    const response = await axios.get(apiUrl);

    appLog("inst");
    appLog(response);
    setInstitutes(response.data);
  };
  const fetchInstitutionsForProgram = async (programId) => {
    const apiUrl = baseUrl + `adm/index.php?q=i&p=${programId}`; // Adjust API endpoint accordingly
    const response = await axios.get(apiUrl);
    appLog("i for p");
    appLog(response);

    setInstitutes(response.data);


    return response.data;
  };

  const fetchGrades = async (instituteId, instype) => {
    const apiUrl = baseUrl + `adm/index.php?q=g&i=${instituteId}&it=${instype}`;
    const response = await axios.get(apiUrl);
    appLog("grades");
    appLog(response);
    setGrades(response.data);
    //setGradeSections([]); // Clear grade sections when grade changes
    //fetchGradeSections(instituteId, instype);
  };

  const fetchGradeSections = async (instituteId, instype) => {
    const apiUrl = baseUrl + `adm/index.php?q=gs&gi=${instituteId}&it=${instype}`;
    const response = await axios.get(apiUrl);
    appLog("gradesections");
    appLog(response);
    setGradeSections(response.data);
  };

  const handleInstituteChange = (event) => {
    const instituteId = parseInt(event.target.value, 10);
    appLog("Institution Id");
    const institute = institutes.find(inst => inst.ins_id === instituteId);
    const ins_type = institute.ins_type;
    const institute_name = institute.name;
    appLog(instituteId);
    appLog(event.target.value,)

    setSelectedInstitute(event.target.value);

    setSelectedInstype(ins_type);

    fetchGrades(instituteId, ins_type);
    fetchGradeSections(instituteId, ins_type);

    setFormValues({ ...formValues, institute_id: instituteId, institute: institute_name, ins_type: ins_type });
  };

  const handleGradeChange = (event) => {
    const gradeId = parseInt(event.target.value, 10);
    appLog("Grade Id");
    const gradeInfo = grades.find(grd => grd.grade_id === gradeId);
    const grade_name = gradeInfo.grade;
    setSelectedGrade(event.target.value);
    setFormValues({ ...formValues, grade_id: gradeId, grade_name: grade_name });
  };
  const handleGradeSectionChange = (event) => {
    const gradeSectionId = parseInt(event.target.value, 10);
    appLog("Grade Section Id");
    const gradeSectionInfo = gradeSections.find(grd => grd.section_id === gradeSectionId);
    const section_name = gradeSectionInfo.section_name;
    setSelectedGradeSection(event.target.value);

    setFormValues({ ...formValues, grade_section_id: gradeSectionId, grade_section_name: section_name });
  };

  const handleProgramChange = (event) => {
    if (event.target.value != '') {
      const programId = parseInt(event.target.value, 10);
      const program = programs.find(p => p.id === parseInt(programId));
      setSelectedProgram(program);
      //console.log(program);
      setFormValues({
        ...formValues,
        programId: programId,
        program: program.title,
        programDesc: program.description,
        amount: program.fees,
        type: "Professional",
      });
      const institutionsForProgram = fetchInstitutionsForProgram(programId);
      if (institutionsForProgram) {
        appLog(institutionsForProgram);
        setInstitutes(institutionsForProgram);
      }
    }
    else {
      setInstitutes([]);
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const formatTime = (time) => {
    const date = new Date(time);

    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    // Use toLocaleTimeString with UTC time zone to get the correct display
    return date.toLocaleTimeString('en-GB', { ...options, timeZone: 'UTC' });
  };

  const displayProgramDate = (selectedProgram) => {
    const startDate = new Date(selectedProgram.start_date);
    const completionDate = new Date(selectedProgram.completion_date);
    console.log(selectedProgram);
    console.log(startDate);
    console.log(completionDate);
    console.log(startDate == completionDate);

    if (startDate.toDateString() === completionDate.toDateString()) {
      return formatDate(selectedProgram.start_date); // Only return start date
    } else {
      return `${formatDate(selectedProgram.start_date)} - ${formatDate(selectedProgram.completion_date)}`; // Return both dates
    }
  };
  const isValidProgram = (program) => {
    //console.log(program);
    return program && program.description && program.start_date && program.completion_date;
  };

  const navigate = useNavigate();
  let { id } = useParams();

  async function getUserDetails(id, source) {
    const requestBody = { "lni-user-id": id };
    await postCall(USER_API.USER, requestBody, {}, source)
      .then((res) => {
        if (res.status === 200) {
          setUserDetails((prev) => ({ ...prev, data: res?.data }));
        }
      })
      .catch((error) => {
        if (error) {
          appLog(error.message); // => the response payload
        }
      })
      .finally(() => setUserDetails((prev) => ({ ...prev, isLoading: false })));
  }

  useEffect(() => {
    const source = axiosSource();
    if (id) {
      setUserDetails((prev) => ({ ...prev, isLoading: true }));
      getUserDetails(Number(id), source);
    }
    return () => {
      source.cancel();
    };
  }, [id]);

  useEffect(() => {
    if (!userDetails.data) return;
    setFormValues({
      institute_id: userDetails.data["institute_id"],
      institute: userDetails.data["institute"],
      grade_id: userDetails.data["grade_id"],
      grade: userDetails.data["grade"],
      grade_section_id: userDetails.data["grade_section_id"],
      grade_section_name: userDetails.data["grade_section_name"],
      programId: userDetails.data["programId"],
      program: userDetails.data["program"],
      programDesc: userDetails.data["programDesc"],
      amount: userDetails.data["amount"],
      type: userDetails.data["type"],
      profession: userDetails.data["profession"],
      fname: userDetails.data["fname"],
      lname: userDetails.data["lname"],
      email: userDetails.data["email"],
      phone: userDetails.data["phone"],
      gender: userDetails.data["gender"],
      age: userDetails.data["age"],
      country: userDetails.data["country"],
      city: userDetails.data["city"],
      state: userDetails.data["state"],
      transId: userDetails.data["transId"],
      trans_status: "gpaid",
      trans_date: new Date().toISOString(),
      utype: "Student",
      active: userDetails.data["active"],
      "lni-user-id": userDetails.data["lni-user-id"],
      "auth59-user-id": userDetails.data["auth59-user-id"],
    });
  }, [userDetails.data]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormValues((prev) => {
      const newFormValues = { ...prev, [name]: value };

      if (name === "country") {
        setStates(State.getStatesOfCountry(value));
        setCities([]);
        return { ...newFormValues, state: "", city: "" };
      }

      if (name === "state") {
        setCities(City.getCitiesOfState(prev.country, value));
        return { ...newFormValues, city: "" };
      }



      return newFormValues;
    });

    setFormErrors({});
  }, []);

  const lookupRegister = async (requestBody) => {
    try {
      const response = await postCall(USER_API.LOOKUPREGISTER, requestBody);
      //console.log(requestBody);
      //console.log("Payment success call:", response.data);
      await initializeRazorpay(requestBody, toast, setModalVisible);
    } catch (error) {
      console.error("Error in payment success call:", error);
      return false;
    }
  };

  const gpayRegister = async (requestBody) => {
    try {
      //const response = await postCall(USER_API.LOOKUPREGISTER, requestBody);
      //console.log(requestBody);
      //console.log("Payment success call:", response.data);
      await initializeGpay(requestBody, toast, setModalVisible);
    } catch (error) {
      console.error("Error in payment success call:", error);
      return false;
    }
  };

  appLog(modalVisible);

  const handleSubmit = useCallback(
    (e) => {
      // if (!showTime) {
      //   toast({
      //     title: "Please Click Program:A21Skills" || "",
      //     status: "error",
      //     duration: CONSTANTS.DURATION,
      //   });
      // } else {
      // }
      e.preventDefault();
      if (validate(formValues, setFormErrors)) {
        setIsLoading(true);

        const requestBody = {
          ...formValues,
          "auth59-user-id": formValues["auth59-user-id"],
          "lni-user-id": formValues["lni-user-id"],
        };

        //lookupRegister(requestBody);
        gpayRegister(requestBody);

        setIsLoading(false);
      }
    },
    [formValues, showTime]
  );

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setCountries(Country.getAllCountries());
    setStates(State.getStatesOfCountry("IN"));
    setFormValues((prev) => ({ ...prev, country: "IN" }));
  }, []);

  const programDesc = formValues.programDesc;
  const regex = /(.*)\s*\((.*)\)/;
  const match = programDesc.match(regex);

  let mainDesc = programDesc;
  let details = "";

  if (match) {
    mainDesc = match[1].trim();
    details = match[2].trim();
  }

  return (
    <>
      {(userDetails.isLoading || isLoading) && (
        <div className="loader flex-center">Loading...</div>
      )}
      <Flex alignItems="center" justifyContent="center">
        <Card m={2} mt={5}>
          <CardBody h={{ base: "87vh", lg: "125vh" }}>
            <Flex justifyContent={"center"}>
              <form className="bulkupload-section form-data">
                <Heading mb={4} textAlign={"center"}>
                  {id ? "Edit User" : screenName}
                </Heading>
                <Flex
                  flexDirection="column"
                  justifyContent={"center"}
                  minWidth={{ base: 300, md: 500, lg: 500 }}
                  maxWidth={300}
                >
                  <FormControl isInvalid={formErrors.program}>
                    <FormLabel fontSize="sm">
                      Select Program:
                      <Select onChange={handleProgramChange}>
                        <option value="">Select a program</option>
                        {programs.map(program => (
                          <option key={program.id} value={program.id}>
                            {program.title}
                          </option>
                        ))}
                      </Select>
                    </FormLabel>
                  </FormControl>

                  {isValidProgram(selectedProgram) && (
                    <>
                      <FormControl>
                        <FormLabel fontSize="sm">
                          Program Description:&nbsp;&nbsp;&nbsp;&nbsp;{selectedProgram.description}
                        </FormLabel>
                        {showTime && (
                          <>
                            <FormLabel ml="110px" fontSize="sm">
                              ${displayProgramDate(selectedProgram)}
                            </FormLabel>
                            <FormLabel ml="110px" fontSize="sm">
                              {`${formatTime(selectedProgram.start_time)} - ${formatTime(selectedProgram.completion_time)}`}
                            </FormLabel>
                          </>
                        )}
                      </FormControl>

                      <FormControl>
                        <FormLabel fontSize="sm">
                          Program Date:&nbsp;&nbsp;&nbsp;&nbsp;{displayProgramDate(selectedProgram)}
                        </FormLabel>
                        <FormLabel ml="110px" fontSize="sm">
                          {`${formatTime(selectedProgram.start_time)} - ${formatTime(selectedProgram.completion_time)}`}
                          <Box mt={4} p={3} borderWidth="1px" borderRadius="md" bg="red.100" borderColor="red.300">
                            <Text fontSize="md" fontWeight="bold" color="red.800">
                              Please complete the form, proceed with the payment, and click the 'Register' button. You should receive a 'Thank You' message upon successful registration. If you do not receive this message, kindly email us at <Text as="span" color="blue.500">info@a3minds.com</Text> with your name, phone, email, date, and transaction details.
                            </Text>
                          </Box>
                        </FormLabel>
                      </FormControl>
                    </>
                  )}

                  <FormControl isInvalid={formErrors.fname}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      size="sm"
                      type="text"
                      name="fname"
                      placeholder="Enter First Name"
                      value={formValues.fname}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{formErrors.fname}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.lname}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      size="sm"
                      type="text"
                      name="lname"
                      placeholder="Enter Last Name"
                      value={formValues.lname}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{formErrors.lname}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.email}>
                    <FormLabel>Email Address</FormLabel>
                    <Input
                      size="sm"
                      type="text"
                      name="email"
                      placeholder="Enter Email Address"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.phone}>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      size="sm"
                      type="phone"
                      name="phone"
                      placeholder="Enter Phone Number"
                      max={10}
                      value={formValues.phone}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.gender}>
                    <FormLabel>Gender</FormLabel>
                    <Select
                      size="sm"
                      value={formValues.gender}
                      name="gender"
                      id="gender"
                      onChange={handleChange}
                    >
                      <option value="">-Select-</option>
                      <option value={CONSTANTS.GENDER.MALE}>Male</option>
                      <option value={CONSTANTS.GENDER.FEMALE}>Female</option>
                      <option value={CONSTANTS.GENDER.OTHERS}>Others</option>
                    </Select>
                    <FormErrorMessage>{formErrors.gender}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.age}>
                    <FormLabel>Age</FormLabel>
                    <Input
                      size="sm"
                      type="text"
                      name="age"
                      min={10}
                      max={99}
                      maxLength={2}
                      placeholder="Enter Age"
                      value={formValues.age}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{formErrors.age}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.type}>
                    {/* <FormLabel>Type</FormLabel> */}
                    <Select
                      size="sm"
                      value={formValues.type}
                      name="type"
                      id="type"
                      onChange={handleChange}
                      required
                      display="none"
                    >
                      <option value="">-Select-</option>
                      <option value="Student">Student</option>
                      <option value="Professional" selected='selected'>Professional</option>
                    </Select>
                    <FormErrorMessage>{formErrors.type}</FormErrorMessage>
                  </FormControl>

                  {formValues.type === "Student" && (
                    <>
                      <FormControl isInvalid={formErrors.institute_id}>
                        <FormLabel>Institute</FormLabel>
                        <Select
                          size="sm"
                          value={formValues.institute_id}
                          name="institute"
                          id="institute"
                          onChange={handleInstituteChange}
                        >
                          <option value="">-Select-</option>
                          {Array.isArray(institutes) && institutes.map((institute, index) => (
                            <option key={index} value={institute.ins_id}>
                              {institute.name}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>{formErrors.institute_id}</FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={formErrors.grade_id}>
                        <FormLabel>Grade</FormLabel>
                        <Select
                          size="sm"
                          value={formValues.grade_id}
                          name="grade"
                          id="grade"
                          onChange={handleGradeChange}
                        >
                          <option value="">-Select-</option>
                          {grades.map((grade, index) => (
                            <option key={index} value={grade.grade_id}>
                              {grade.grade}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>{formErrors.grade_id}</FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={formErrors.grade_section_id}>
                        <FormLabel>Grade Section</FormLabel>
                        <Select
                          size="sm"
                          value={formValues.grade_section_id}
                          name="gradeSection"
                          id="gradeSection"
                          onChange={handleGradeSectionChange}
                        >
                          <option value="">-Select-</option>
                          {gradeSections.map((section, index) => (
                            <option key={index} value={section.section_id}>
                              {section.section_name}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>{formErrors.grade_section_id}</FormErrorMessage>
                      </FormControl>
                    </>
                  )}

                  {formValues.type === "Professional" && (
                    <FormControl isInvalid={formErrors.profession}>
                      <FormLabel>Profession</FormLabel>
                      <Input
                        size="sm"
                        value={formValues.profession}
                        name="profession"
                        id="profession"
                        onChange={handleChange}
                        maxLength={20}
                        pattern="[A-Za-z\s]*"
                      />
                      <FormErrorMessage>{formErrors.profession}</FormErrorMessage>
                    </FormControl>
                  )}
                  <FormControl isInvalid={formErrors.country}>
                    <FormLabel>Country</FormLabel>
                    <Select
                      size="sm"
                      value={formValues.country}
                      name="country"
                      id="country"
                      onChange={handleChange}
                    >
                      <option value="">-Select-</option>
                      {countries.map((country) => (
                        <option key={country.isoCode} value={country.isoCode}>
                          {country.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{formErrors.country}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={formErrors.state}>
                    <FormLabel>State</FormLabel>
                    <Select
                      size="sm"
                      value={formValues.state}
                      name="state"
                      id="state"
                      onChange={handleChange}
                      disabled={!formValues.country}
                    >
                      <option value="">-Select-</option>
                      {states.map((state) => (
                        <option key={state.isoCode} value={state.isoCode}>
                          {state.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{formErrors.state}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={formErrors.city}>
                    <FormLabel>City</FormLabel>
                    <Select
                      size="sm"
                      value={formValues.city}
                      name="city"
                      id="city"
                      onChange={handleChange}
                      disabled={!formValues.state}
                    >
                      <option value="">-Select-</option>
                      {cities.map((city) => (
                        <option key={city.name} value={city.name}>
                          {city.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{formErrors.city}</FormErrorMessage>
                  </FormControl>
                  {isValidProgram(selectedProgram) && (
                    <>
                      <div style={{ marginTop: '20px' }}>
                        <img
                          src={a3mindsupi} // Replace with your QR code image path
                          alt="UPI QR Code"
                          style={{ width: '100%', height: '450px' }} // Adjust size as needed
                        />
                        <h3>Pay INR {selectedProgram.fees}. <strong>If the QR code doesn't work, please make GPay to 9551000555</strong> and mention Transaction ID and click Register. </h3>
                        <Box mt={4} p={3} borderWidth="1px" borderRadius="md" bg="red.100" borderColor="red.300">
                          <Text fontSize="md" fontWeight="bold" color="red.800">
                            Please complete the form, proceed with the payment, and click the 'Register' button. You should receive a 'Thank You' message upon successful registration. If you do not receive this message, kindly email us at <Text as="span" color="blue.500">info@a3minds.com</Text> with your name, phone, email, date, and transaction details.
                          </Text>
                        </Box>
                      </div>

                      <FormControl isInvalid={formErrors.transId} mt={4}>
                        <FormLabel>Transaction ID</FormLabel>
                        <Input
                          type="text"
                          name="transId"
                          id="transId"
                          value={formValues.transId}
                          onChange={handleChange}
                          maxLength={50}
                          required
                        />
                        <FormErrorMessage>{formErrors.transId}</FormErrorMessage>
                      </FormControl>
                    </>
                  )}


                  <Box mt={2}>
                    <Flex align={"center"} justify={"center"} gap={2}>
                      <Button
                        size={"sm"}
                        colorScheme="blue"
                        disabled={isLoading}
                        isLoading={isLoading}
                        onClick={handleSubmit}
                      >
                        {create ? (id ? "Update" : "Submit") : "Register"}
                      </Button>
                      <Button
                        size={"sm"}
                        colorScheme="gray"
                        type="button"
                        onClick={() => {
                          navigate("/home");
                        }}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
              </form>
            </Flex>
          </CardBody>
        </Card>
      </Flex>

      {modalVisible && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlay />
          <ModalContent bg="white">
            <ModalHeader bg="white">Registration Successful</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Image width="200px" src={qr} alt="qr" />
                <Text fontSize="2xl" mt={4}>
                  Thank you for registering with us.
                </Text>
                <Text fontSize="md" mt={4}>
                  We will send the details / online meeting link to your E-Mail
                  / SMS / WhatsApp
                </Text>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" onClick={() => navigate("../home")}>
                close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default RegisterUser;
